<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="山东大学" @moTitltFn="navPanel"></MoTitle>
    <div class="moSanDong">
      <!-- 头部 -->
      <div class="moTitle center">
      </div>
      <!-- 高校简介 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">高校简介</h2>
        <span class="spanDesc"></span>
        <div style="margin:30px 0 40px">
            <p style="line-height: 1.5rem;margin-bottom:10px">
                山东大学是一所历史悠久、学科齐全、实力雄厚、特色鲜明的教育部直属重点综合性大学，在国内外具有重要影响，2017年顺利迈入世界一流大学建设高校（A类）行列。
            </p>
            <p style="line-height: 1.5rem;">
                山东大学前身是1901年创办的山东大学堂，被誉为中国近代高等教育起源性大学。其医学学科起源于1864年，开启近代中国高等医学教育之先河。从诞生起，学校先后历经了山东大学堂、国立青岛大学、国立山东大学、山东大学以及由原山东大学、山东医科大学、山东工业大学三校合并组建的新山东大学等几个历史发展时期。120年来，山东大学始终秉承“为天下储人才，为国家图富强”的办学宗旨，深入践行“学无止境，气有浩然”的校训精神，踔厉奋发，薪火相传，积淀形成了“崇实求新”的校风，培养了60余万各类人才，为国家和区域经济社会发展作出了重要贡献。
            </p>
        </div>
      </div>
      <!-- 建设挑战 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">建设挑战</h2>
        <span class="spanDesc"></span>
        <div style="margin:10px 0 40px;width:100%">
              <MoListDesc :descList="constructionDesc" imgWidth="50px" :showBgColor="true" ListHeight="7.5rem"></MoListDesc>
          </div>
      </div>
      <!-- 总体规划 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">总体规划</h2>
        <span class="spanDesc"></span>
        <p style="line-height: 1.5rem;margin:30px 0 10px">山东大学人力资源信息化建设始于2018年，以山东大学智慧校园建设的整体规划为指导，“一校三地”统一规划，充分融合现有资源，提升服务与管理效率。项目聚焦于教职工服务、教职工管理、教职工发展、人才队伍建设决策和教职工数据管理等五个层面的“一体化”。</p>
        <div style="margin-bottom:40px;width:100%">
              <MoListDesc :descList="step" imgWidth="50px"></MoListDesc>
          </div>
      </div>
      <!-- 建设成果 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">建设成果</h2>
        <span class="spanDesc"></span>
        <p style="line-height: 1.5rem;margin:30px 0 20px">基于山东大学“一校三地”的办学规格，采用“微应用+大平台”的架构，用统一的平台融合不同校区的差异化管理机制，建设了“一校三地”统一的人力资源管理与服务平台。</p>
        <img src="@/assets/TypicalCase/sandong/achievement.png" alt="" style="width:100%;margin-bottom:20px">
        <div style="margin-bottom:30px">
               <MoListDesc :descList="value" imgWidth="50px"></MoListDesc>
        </div>
      </div>
      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import MoListDesc from '@/components/moListDesc'
export default {
  name: 'moSanDong',
  components: {
    MoTitle,
    MoListDesc
  },
  data () {
    return {
      isScroll: false,
      constructionDesc: [
        {
          src: require('@/assets/TypicalCase/zhongsan/one.png'),
          title: '办学格局的挑战',
          desc: [
            {
              p: '校区分散在不同城市，形成了一校三地（济南、威海、青岛）的办学格局。'
            }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/two.png'),
          title: '机制层面的挑战',
          desc: [
            {
              p: '不符合信息化要求，但积累多年的制度政策及业务流程需要被突破。'
            }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/three.png'),
          title: '观念层面的挑战',
          desc: [
            {
              p: '信息化的推进将影响现有业务的习惯性做法，对部门及个人的工作形式将提出新的要求。'
            }
          ]
        }
      ],
      step: [
        {
          title: '提升服务响应能力',
          desc: [
            { p: '强调以人为本，面向不同角色、不同业务、不同场景构建人性化的服务和应用，提升多端异屏体验的友好性、一致性。' }
          ],
          src: require('@/assets/TypicalCase/sandong/xiangying.png')
        },
        {
          title: '提升管理支持能力',
          desc: [
            { p: '通过构建统一的平台，消除数据孤岛、突破部门壁垒，实现各部门协同办公。' }
          ],
          src: require('@/assets/TypicalCase/sandong/zhichi.png')
        },
        {
          title: '提升决策支持能力',
          desc: [
            { p: '通过数据挖掘与分析技术，在人才队伍建设、绩效评估、薪酬激励、杰出人才引进关键业务方面洞悉趋势、发现问题与机会，辅助领导决策。' }
          ],
          src: require('@/assets/TypicalCase/sandong/juece.png')
        },
        {
          title: '提升数据管理能力',
          desc: [
            { p: '基于统一的数据治理标准，实现教职工数据的统一采集、维护、共享和展现，明确数据维护和管理责任，发挥数据的价值。' }
          ],
          src: require('@/assets/TypicalCase/zhongsan/data.png')
        }
      ],
      value: [
        {
          src: require('@/assets/TypicalCase/zhongsan/one.png'),
          title: '数据层面',
          desc: [
            {
              p: '建立了“一校三地”统一的教职工数据中心，并梳理和整合了人事相关数据，按“一数一源一责”的原则明确数据采集和维护机制，保障和提升了数据质量。'
            }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/two.png'),
          title: '业务层面',
          desc: [
            {
              p: '按部门职责梳理业务管理流程，实现业务对接与协同、以教职工为中心提供更加丰富、便捷的服务功能和应用。最大程度的实现了业务办理的网络化、自动化和服务提供的自助化、移动化，把“人”从事务中解放出来，提高了工作效率。'
            }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/three.png'),
          title: '服务层面',
          desc: [
            {
              p: '为教职工提供了不同的服务入口，提升了教职工的体验感和获得感。'
            }
          ]
        }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/typicalCase/sanDong')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/TypicalCase/sandong/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moSanDong{
  padding-top: 2.75rem;
}
</style>
